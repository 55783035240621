import React from "react";
import classNames from "classnames";

import css from "./logo.module.scss";

interface Props {
  variant: "light" | "dark" | "audioteka" | "audioteka-klub" | "audioteka-klub-pink";
  className?: string;
}

export function Logo({ className, variant }: Props) {
  return (
    <svg className={classNames(css.logo, className)} aria-label="Audioteka">
      <use href={`/img/logo02.svg#${variant}`} />
    </svg>
  );
}
