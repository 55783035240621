import { isId } from "utils/string";

import { audiotekaApi } from "./AudiotekaApi";
import {
  Audiobook,
  Catalog,
  Category,
  CategoryList,
  CycleList,
  DownloadableAudiobook,
  Favourites,
  HomeScreen,
  LicenseChannels,
  PodcastList,
  Product,
  ProductList,
  ProductRelations,
  ProductReview,
  ProductReviewList,
  SearchScreen,
  HelpCenterScreen,
  UserRecentlyPlayed,
  UserShelf,
  Cycle,
  AudiobookMedia,
  AlgoliaSearchContext,
  User,
  ActivationMethod,
} from "./AudiotekaApi.types";

const endpoints = {
  audiobook: (audiobookId: string) => `/audiobooks/${audiobookId}`,
  product: (productId: string) => `/products/${productId}`,
  catalog: (catalogId: string) => `/visitor/${catalogId}/screen/catalog`,
  categoryById: (categoryId: string) => `/categories/${categoryId}`,
  categoryBySlug: (categorySlug: string) => `/categories/slug/${categorySlug}`,
  categoryList: "/categories",
  categoryProductList: (categoryId: string) => `/categories/${categoryId}/products`,
  cycleById: (cycleId: string) => `/cycles/${cycleId}`,
  cycleBySlug: (cycleSlug: string) => `/cycles/slug/${cycleSlug}`,
  cycleProductList: (cycleId: string) => `/cycles/${cycleId}/products`,
  cycleList: "/cycles",
  homeScreen: (catalogId: string) => `/visitor/${catalogId}/screen`,
  searchScreen: (catalogId: string) => `/visitor/${catalogId}/screen/search`,
  helpCenterScreen: (catalogId: string) => `/visitor/${catalogId}/screen/help-center`,
  podcastList: "/podcasts",
  licenseChannels: (audiobookId: string) => `/me/audiobook-license-channels/${audiobookId}`,
  productReviewList: (catalogId: string, audiobookId: string) =>
    `/catalogs/${catalogId}/products/${audiobookId}/reviews`,
  productReview: (productId: string) => `/me/products/${productId}/review`,
  searchSlug: (type: string) => `/search/${type}/get-slug`,
  userCatalog: "/me/screen/catalog",
  userHomeScreen: "/me/screen",
  userFavourites: "/me/favourites",
  userRecentlyPlayed: "/me/recently-played",
  userShelf: "/me/shelf",
  audiobookMedia: (audiobookId: string, catalogId: string) => `/catalogs/${catalogId}/audiobooks/${audiobookId}/media`,
  user: "/me",
  userActivationMethods: "/me/activation-methods",
  activationMethods: (catalogId: string) => `/visitor/${catalogId}/activation-methods`,
  algolia: (catalogId: string) => `/visitor/${catalogId}/algolia`,
};

const commands = {
  audiobookDownload: "RequestAudiobookDownload",
  rateAndReviewProduct: "RateAndReviewProduct",
  rateProduct: "RateProduct",
  removeFromFavourites: "RemoveFromFavourites",
  addToFavourites: "AddToFavourites",
  getProductRelations: "GetProductRelations",
  registerUser: "RegisterUser",
  authenticateForWeb: "AuthenticateForWeb",
  logoutForWeb: "LogoutForWeb",
} as const;

/*
  GET
*/

export const getHomeScreen = (catalogId: string) =>
  audiotekaApi.get<HomeScreen>({ url: endpoints.homeScreen(catalogId), params: { "_f[noLinks]": 1 } });

export const getProduct = (productId: string, catalogId: string) =>
  audiotekaApi.get<Product>({ url: endpoints.product(productId), params: { catalog_id: catalogId, strict: 1 } });

export const getAudiobook = (audiobookId: string, catalogId: string, notStrict = false) =>
  audiotekaApi.get<Audiobook>({
    url: endpoints.audiobook(audiobookId),
    params: { catalog: catalogId, strict: notStrict ? 0 : 1 },
  });

export const getScreenCatalog = (catalogId: string) => audiotekaApi.get<Catalog>({ url: endpoints.catalog(catalogId) });

export const getCategory = (categoryIdOrSlug: string, catalogId: string, params: { embed_screen?: 1 } = {}) =>
  audiotekaApi.get<Category>({
    url: isId(categoryIdOrSlug) ? endpoints.categoryById(categoryIdOrSlug) : endpoints.categoryBySlug(categoryIdOrSlug),
    params: { catalog: catalogId, ...params },
  });

export const getCategoryList = (catalogId: string) =>
  audiotekaApi.get<CategoryList>({
    url: endpoints.categoryList,
    params: { catalog: catalogId },
  });

export const getCycle = (cycleId: string, catalogId: string, params: { embed_screen?: 1 } = {}) =>
  audiotekaApi.get<Cycle>({
    url: isId(cycleId) ? endpoints.cycleById(cycleId) : endpoints.cycleBySlug(cycleId),
    params: { catalog: catalogId, "_f[noLinks]": true, strict: 1, ...params },
  });

export const getCycleProductsList = (
  cycleId: string,
  catalogId: string,
  params: { page?: number | string; limit?: number | string } = {}
) =>
  audiotekaApi.get<ProductList>({
    url: endpoints.cycleProductList(cycleId),
    params: { ...params, catalog: catalogId, "_f[noLinks]": true },
  });

export const getCycleList = (
  catalogId: string,
  params: {
    page?: number;
    limit?: number;
  }
) => audiotekaApi.get<CycleList>({ url: endpoints.cycleList, params: { ...params, catalog: catalogId } });

export const getPodcastList = (
  catalogId: string,
  params: {
    page?: number;
    limit?: number;
  }
) => audiotekaApi.get<PodcastList>({ url: endpoints.podcastList, params: { ...params, catalog: catalogId } });

export const getProductReviewList = (catalogId: string, audiobookId: string) =>
  audiotekaApi.get<ProductReviewList>({
    url: endpoints.productReviewList(catalogId, audiobookId),
  });

export const getCategoryProductList = (
  catalogId: string,
  categoryId: string,
  params: {
    club?: boolean;
    limit?: number;
    order?: string;
    page?: number;
    sort?: string;
  }
) =>
  audiotekaApi.get<ProductList>({
    url: endpoints.categoryProductList(categoryId),
    params: { ...params, catalog: catalogId },
  });

export const getUserProductReview = (productId: string) =>
  audiotekaApi.get<ProductReview>({
    url: endpoints.productReview(productId),
  });

export const getLicensesChannels = (audiobookId: string) =>
  audiotekaApi.get<LicenseChannels>({
    url: endpoints.licenseChannels(audiobookId),
  });

export const getUserCatalog = () => audiotekaApi.get<Catalog>({ url: endpoints.userCatalog });

export const getUserHomeScreen = () =>
  audiotekaApi.get<HomeScreen>({
    url: endpoints.userHomeScreen,
    params: { "_f[noLinks]": 1 },
  });

export const getReviewsPage = ({
  catalogId,
  productId,
  page,
  limit,
}: {
  catalogId: string;
  productId: string;
  page: number;
  limit?: number;
}) =>
  audiotekaApi.get<ProductReviewList>({
    url: endpoints.productReviewList(catalogId, productId),
    params: { page, limit },
  });

export const getSlug = (type: string, query: string) => {
  return audiotekaApi.get<{ slug: string }>({ url: endpoints.searchSlug(type), params: { query } });
};

export const getFavourites = (limit: number, page?: number) =>
  audiotekaApi.get<Favourites>({
    url: endpoints.userFavourites,
    params: { "_f[noLinks]": 1, limit, page },
  });

export const getUserRecentlyPlayed = () => audiotekaApi.get<UserRecentlyPlayed>({ url: endpoints.userRecentlyPlayed });

export const getUserShelf = (params?: { page: number; limit?: number; sort?: string; order?: "desc" | "asc" }) =>
  audiotekaApi.get<UserShelf>({ url: endpoints.userShelf, params });

export const getSearchScreen = (catalogId: string) =>
  audiotekaApi.get<SearchScreen>({ url: endpoints.searchScreen(catalogId) });

export const getHelpCenterScreen = (catalogId: string) =>
  audiotekaApi.get<HelpCenterScreen>({ url: endpoints.helpCenterScreen(catalogId) });

export const getAudiobookMedia = (audiobookId: string, catalogId: string) =>
  audiotekaApi.get<AudiobookMedia>({ url: endpoints.audiobookMedia(audiobookId, catalogId) });

export const getUser = () => audiotekaApi.get<User>({ url: endpoints.user });

export const getUserActivationMethods = () =>
  audiotekaApi.get<{ methods: ActivationMethod[] }>({
    url: endpoints.userActivationMethods,
    params: { "_f[noLinks]": 1, sort: "position", order: "asc" },
  });

export const getActivationMethods = (catalogId: string) =>
  audiotekaApi.get<{ methods: ActivationMethod[] }>({
    url: endpoints.activationMethods(catalogId),
    params: { "_f[noLinks]": 1, sort: "position", order: "asc" },
  });

export const getAlgoliaSearchContext = (catalogId: string) =>
  audiotekaApi.get<AlgoliaSearchContext>({ url: endpoints.algolia(catalogId) });

/*
  POST
*/

export const audiobookDownloadCommands = (audiobookId: string) =>
  audiotekaApi.sendCommand<{ name: typeof commands.audiobookDownload; audiobook_id: string }, DownloadableAudiobook>({
    name: commands.audiobookDownload,
    audiobook_id: audiobookId,
  });

export const rateAndReviewProductCommands = ({
  productId,
  review,
  signature,
  rating,
}: {
  productId: string;
  review: string;
  signature: string;
  rating: number;
}) =>
  audiotekaApi.sendCommand<
    {
      name: typeof commands.rateAndReviewProduct;
      product_id: string;
      review: string;
      author_signature: string;
      rating: number;
    },
    {}
  >({ name: commands.rateAndReviewProduct, product_id: productId, review, author_signature: signature, rating });

export const rateProductCommands = ({ productId, rating }: { productId: string; rating: number }) =>
  audiotekaApi.sendCommand<{ name: typeof commands.rateProduct; product_id: string; rating: number }, {}>({
    name: commands.rateProduct,
    product_id: productId,
    rating,
  });

export const removeFromFavourites = (productId: string) =>
  audiotekaApi.sendCommand<{ name: typeof commands.removeFromFavourites; product_id: string }, {}>({
    name: commands.removeFromFavourites,
    product_id: productId,
  });

export const addToFavourites = (productId: string) =>
  audiotekaApi.sendCommand<{ name: typeof commands.addToFavourites; product_id: string }, {}>({
    name: commands.addToFavourites,
    product_id: productId,
  });

export const getProductRelations = (productIds: string[]) =>
  audiotekaApi.sendCommand<{ name: typeof commands.getProductRelations; product_ids: string[] }, ProductRelations>({
    name: commands.getProductRelations,
    product_ids: productIds,
  });

export const registerUser = ({
  email,
  password,
  catalogId,
  termsOfService,
  marketingConsent,
}: {
  email: string;
  password: string;
  catalogId: string;
  termsOfService: boolean;
  marketingConsent: boolean;
}) =>
  audiotekaApi.sendCommand<
    {
      name: typeof commands.registerUser;
      email: string;
      password: string;
      catalog_id: string;
      terms_of_service: boolean;
      marketing_consent: boolean;
    },
    {}
  >({
    name: commands.registerUser,
    email,
    password,
    catalog_id: catalogId,
    terms_of_service: termsOfService,
    marketing_consent: marketingConsent,
  });

export const loginUser = ({
  deviceId,
  email,
  password,
  catalogId,
  recaptchaToken,
}: {
  deviceId: string;
  email: string;
  password: string;
  catalogId: string;
  recaptchaToken: string;
}) =>
  audiotekaApi.sendCommand<
    {
      name: typeof commands.authenticateForWeb;
      device_id: string;
      email: string;
      password: string;
      catalog_id: string;
      recaptcha_token: string;
    },
    { expires_at: string }
  >({
    name: commands.authenticateForWeb,
    device_id: deviceId,
    email,
    password,
    catalog_id: catalogId,
    recaptcha_token: recaptchaToken,
  });

export const logoutUser = (catalogId: string) =>
  audiotekaApi.sendCommand<{ name: typeof commands.logoutForWeb; catalog_id: string }, {}>({
    name: commands.logoutForWeb,
    catalog_id: catalogId,
  });
