import React, { useEffect } from "react";
import classNames from "classnames";

import { Link } from "app-components/link";
import { NewIcon } from "components/NewIcon";
import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { useTeaserTracking } from "modules/teaser-tracking";

import { ActionButton } from "./action-button/action-button";
import { FooterBottom, FooterRight } from "./footer";
import css from "./teaser.module.scss";

interface Props {
  action?: React.ComponentProps<typeof ActionButton>["action"];
  colorless?: boolean;
  lazyLoading?: boolean;
  noPrice?: boolean;
  onClick?: (product: HALProduct) => void;
  product: HALProduct;
  progress?: number;
  isStrictLink?: boolean;
}

export const Teaser = ({
  action = "play",
  colorless,
  lazyLoading,
  noPrice,
  onClick,
  product,
  progress,
  isStrictLink = true,
}: Props) => {
  const teaserTracking = useTeaserTracking();

  const { deeplink: link, description: author, image_url: cover, name: title, slug, rating } = product.data;
  const linkProps = deeplinkTranslator(link, slug);
  const isPlayable = linkProps.route === "audiobook";

  useEffect(() => {
    teaserTracking.addTeaser(product.data.id, product);

    return () => {
      teaserTracking.removeTeaser(product.data.id);
    };
  }, [product.data.id, teaserTracking]);

  const strictParams = isStrictLink ? {} : { "not-strict": "true" };

  return (
    <div className={classNames("adtk-item", css.teaser, { [css.colorless]: colorless })} data-item-id={product.data.id}>
      <div className={css.coverContainer}>
        <img
          className={css.cover}
          loading={lazyLoading ? "lazy" : null}
          src={`${cover}?w=360&auto=format`}
          alt={title}
        />
        <ActionButton className={css.actionButton} action={action} isPlayable={isPlayable} slug={slug} />
        {!!progress && progress > 0 && (
          <div className={css.progress} style={{ "--progress": `${progress}%` } as React.CSSProperties} />
        )}
      </div>
      <p className={css.title}>{title}</p>
      {author && <p className={classNames(css.author, { [css.colorless]: colorless })}>{author}</p>}
      <div className={css.footer}>
        <div className={css.footerTop}>
          {rating > 0 && (
            <div className={classNames(css.rating, { [css.colorless]: colorless })}>
              <NewIcon icon="star" width="13" height="13" />
              {Math.floor(rating * 10) / 10}
            </div>
          )}
          <div className={css.footerSpacer} />
          <FooterRight colorless={colorless} productData={product.data} noPrice={noPrice} />
        </div>
        <FooterBottom productData={product.data} noPrice={noPrice} />
      </div>
      <Link
        className={css.mainLink}
        onClick={() => onClick?.(product)}
        title={product.data.name}
        route={linkProps.route}
        params={{ ...linkProps.params, ...strictParams }}
      />
    </div>
  );
};
