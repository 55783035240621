import React from "react";
import cookie from "js-cookie";
import dynamic from "next/dynamic";
import { useLocale } from "next-intl";

import { useUser } from "modules/user";
import { getLocationInfo } from "utils/getLocationInfo";

import { promoTypesConfig } from "./top-promo-bar.config";

const TopPromoBarComp = dynamic(() => import("./top-promo-bar"), {
  ssr: false,
});

const TopPromoBar = () => {
  const { isLoading: isLoadingUser, isClubMember } = useUser();
  const locale = useLocale();

  if (isLoadingUser) {
    return null;
  }

  const isClubAvailableInLocation = getLocationInfo(locale).features.club;
  const isClubPromoAvailable =
    !isClubMember && cookie.get(promoTypesConfig.club.cookie.key) !== "closed" && isClubAvailableInLocation;
  const availablePromo = isClubPromoAvailable ? "club" : null;

  if (!availablePromo || !(availablePromo in promoTypesConfig)) {
    return null;
  }

  const promo = promoTypesConfig[availablePromo];

  return <TopPromoBarComp promo={promo} />;
};

export default TopPromoBar;
