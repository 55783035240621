export const config = {
  columns: [
    {
      title: "Zákaznícka podpora",
      expandable: true,
      links: [
        { href: "https://audioteka.com/sk/terms-of-use", text: "Obchodné podmienky" },
        { route: "privacyPolicy", text: "Pravidlá ochrany osobných údajov" },
        { route: "helpCenter", text: "Kontakt a pomocník" },
        { className: "manageGdprButton", text: "Nastavenie ochrany osobných údajov" },
      ],
    },
    {
      title: "Ďalšie",
      expandable: true,
      links: [{ href: "https://pages.audioteka.com/sk/pocuvaj/", text: "Zľava 4 € na prvú audioknihu" }],
    },
    {
      title: "Aplikácie",
      apps: {
        android: "https://play.google.com/store/apps/details?id=com.audioteka&hl=sk",
        ios: "https://apps.apple.com/sk/app/audioteka/id1107917398?l=sk",
      },
      links: [{ href: "https://audioteka.com/sk/applications", text: "Všetko" }],
    },
  ],
  social: [
    {
      href: "https://www.facebook.com/audioteka.sk",
      icon: "facebook",
    },
    {
      href: "https://www.instagram.com/audioteka_czsk/",
      icon: "instagram",
    },
    {
      href: "https://www.youtube.com/@audiotekask",
      icon: "youtube",
    },
  ],
  copy: `Copyright © 2015-${new Date().getFullYear()} Audiotéka.cz s.r.o.`,
};
