import React from "react";
import { useLocale } from "next-intl";

import { Container } from "app-components/container";
import { Text } from "app-components/text";
import { Link } from "app-components/link";

import css from "./copyright-notice.module.scss";

export function CopyrightNotice() {
  const locale = useLocale();
  if (locale !== "pl") return null;

  return (
    <>
      <div className={css.divider} />
      <Container>
        <Text type="bodyNormal" className={css.copyrightText}>
          Pobieranie, zwielokrotnianie, przechowywanie lub jakiekolwiek inne wykorzystywanie treści dostępnych w
          niniejszym serwisie - bez względu na ich charakter i sposób wyrażenia (w szczególności lecz nie wyłącznie:
          słowne, słowno-muzyczne, muzyczne, audiowizualne, audialne, tekstowe, graficzne i zawarte w nich dane i
          informacje, bazy danych i zawarte w nich dane) oraz formę (np. literackie, publicystyczne, naukowe,
          kartograficzne, programy komputerowe, plastyczne, fotograficzne) wymaga uprzedniej i jednoznacznej zgody
          Audioteka Group Sp. z o.o. z siedzibą w Warszawie, będącej właścicielem niniejszego serwisu, bez względu na
          sposób ich eksploracji i wykorzystaną metodę (manualną lub zautomatyzowaną technikę, w tym z użyciem programów
          uczenia maszynowego lub sztucznej inteligencji). Powyższe zastrzeżenie nie dotyczy wykorzystywania jedynie w
          celu ułatwienia ich wyszukiwania przez wyszukiwarki internetowe oraz korzystania w ramach stosunków umownych
          lub dozwolonego użytku określonego przez właściwe przepisy prawa. Szczegółowa treść dotycząca niniejszego
          zastrzeżenia znajduje się{" "}
          <Link className={css.link} route="copyrightNotice">
            tutaj
          </Link>
          .
        </Text>
      </Container>
    </>
  );
}
