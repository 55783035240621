import React from "react";

import { NewIcon } from "components/NewIcon";
import { Container } from "app-components/container";
import { Link } from "app-components/link";

import { CopyrightNotice } from "./copyright-notice/copyrigth-notice";
import css from "./bottom.module.scss";

type Social = {
  icon: React.ComponentProps<typeof NewIcon>["icon"];
  href: string;
};

export function Bottom({ socials, copy }: { socials: Social[]; copy: string }) {
  return (
    <div className={css.bottom}>
      <Container className={css.container}>
        <ul className={css.socialIconList}>
          {socials.map((social) => (
            <li key={social.icon}>
              <Link className={css.socialLink} href={social.href}>
                <NewIcon icon={social.icon} width="24" height="24" />
              </Link>
            </li>
          ))}
        </ul>
        <p className={css.copy}>{copy}</p>
      </Container>
      <CopyrightNotice />
    </div>
  );
}
