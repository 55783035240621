import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

import { isServer } from "utils/runtime";
import { ButtonPrimitive } from "app-components/button/button-primitive";
import { NewIcon } from "components/NewIcon";
import { disableScroll, enableScroll } from "utils/window";
import css from "./modal.module.scss";

export interface Props {
  children?: React.ReactNode;
  dark?: boolean;
  mobileFull?: boolean;
  onClose(): void;
  open: boolean;
}

const Modal = ({ children, dark, mobileFull, onClose, open }: Props) => {
  const root = useMemo(() => (isServer ? null : document.createElement("div")), []);
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    document.body.appendChild(root);
    setMounted(true);

    return () => {
      setMounted(false);
      document.body.removeChild(root);
      enableScroll(root);
    };
  }, []);

  useEffect(() => {
    if (open) {
      disableScroll(root);
    } else {
      enableScroll(root);
    }
  }, [open, root]);

  const onClick = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return isMounted
    ? ReactDOM.createPortal(
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
          className={classNames(css.container, {
            [css.container__mobileFull]: mobileFull,
            [css.container__open]: open,
          })}
          onClick={onClick}
        >
          <div
            className={classNames(css.modal, {
              [css.modal__dark]: dark,
              [css.modal__mobileFull]: mobileFull,
              [css.modal__open]: open,
            })}
          >
            <ButtonPrimitive className={css.closeButton} onClick={onClose}>
              <NewIcon icon="close" width="24" height="24" />
            </ButtonPrimitive>
            {children}
          </div>
        </div>,
        root
      )
    : null;
};

export default Modal;
